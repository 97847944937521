import type { DehydratedState } from '@tanstack/vue-query'
import type { Article } from '~/types/article'

// Přesměruje stránku z podskupiny (článek, kategorie..,) pokud se nachází na fixně někde jinde
export default defineNuxtRouteMiddleware(async (to) => {
  switch (to.meta.name) {
    case 'article-detail': {
      const { id } = to.params as { id: string }
      const hasPreview = !!to.query.expires && !!to.query.signature
      const vueQueryState = useState<DehydratedState | null>('vue-query')

      const articleData = vueQueryState.value?.queries.find(
        (data) => data.queryKey[0] === 'articles' && data.queryKey[1] === id
      )?.state?.data as { data: Article } | undefined

      const { data, suspense } = useQuery(
        articleQuery(
          id,
          undefined,
          { header: { fm: 'webp' } },
          hasPreview
            ? { expires: to.query.expires as string, signature: to.query.signature as string }
            : undefined,
          articleData,
          true
        )
      )
      await suspense()
      if (data.value?.data._link) {
        const route = getPermanentRoute(data.value?.data._link)
        if (route) {
          return navigateTo({ path: route, query: { ...to.query } })
        }
      }
    }
  }
})
