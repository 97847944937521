import type { SEO } from '~/types/seo'

export const seoQuery = queryOptions({
  queryKey: ['seo'],
  queryFn: async (): Promise<{
    prefix: string
    suffix: string
    description: string
    keywords: string
  }> => {
    const res = await useCustomFetch<{ data: SEO }>('/public/settings/seo')
    return {
      prefix: res?.data?.value?.data.seo_title_prefix?.value || '',
      suffix: res?.data?.value?.data.seo_title_suffix?.value || '',
      description: res?.data?.value?.data.seo_title_description?.value || '',
      keywords: res?.data?.value?.data.seo_title_keywords?.value || '',
    }
  },
})
