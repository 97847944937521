// TODO: JE ZDE DOČASNĚ VIZ: https://github.com/nuxt/nuxt/issues/28869
import type {
  ComponentCustomOptions as _ComponentCustomOptions,
  ComponentCustomProperties as _ComponentCustomProperties,
} from 'vue'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties extends _ComponentCustomProperties {}
  interface ComponentCustomOptions extends _ComponentCustomOptions {}
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const { loadMessages } = useLocale()
  await loadMessages(nuxtApp.$i18n.locale.value)
})
