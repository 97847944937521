<template>
  <component :is="replacedLink" />
</template>
<script setup lang="ts">
import type { Link } from '~/types/link'
const { hydrateComponents } = useEditor()
const props = defineProps<{ html: string; data?: { [key: string]: Link[] } }>()
const replacedLink = computed(() => {
  return hydrateComponents(props.html, props.data)
})
</script>
