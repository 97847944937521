<template>
  <div
    v-if="query.expires && query.signature"
    class="fixed bottom-0 left-0 bg-basic px-2 py-1 text-white"
  >
    {{ $t('Dočasný náhled platný do') }}
    {{
      Intl.DateTimeFormat(locale.replace('_', '-'), {
        dateStyle: 'long',
        timeStyle: 'short',
      }).format(new Date(new Date(Number(query.expires) * 1000)))
    }}
  </div>
</template>
<script setup lang="ts">
import type { RouteLocation } from 'vue-router'
defineProps<{ query: RouteLocation['query'] }>()

const { locale } = useI18n()
</script>
