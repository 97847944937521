import type { Address, Basket, Item, Slot } from '~/types/basket'
import type { Variant } from '~/types/product'

export function useBasketService() {
  const basketGuidKey = 'basket-guid'
  const basketCountKey = 'basket-count'
  const basketPriceKey = 'basket-price'
  const guidCookie = useCookie(basketGuidKey, { sameSite: true, maxAge: 3153600000 })
  const countCookie = useCookie(basketCountKey, { sameSite: true, maxAge: 3153600000 })
  const priceCookie = useCookie(basketPriceKey, { sameSite: true, maxAge: 3153600000 })
  const basket = useState<{
    guid: null | string
    count: number
    price: number
  }>('basket', () => {
    return {
      guid: guidCookie?.value || null,
      count: countCookie?.value ? Number(countCookie.value) : 0,
      price: priceCookie?.value ? Number(priceCookie.value) : 0,
    }
  })

  const loadBasket = () => {
    basket.value = {
      guid: guidCookie?.value || null,
      count: countCookie?.value ? Number(countCookie.value) : 0,
      price: priceCookie?.value ? Number(priceCookie.value) : 0,
    }
  }

  const setBasket = (guid: string, count: number, price: number) => {
    guidCookie.value = guid
    basket.value.guid = guid
    if (count || count === 0) {
      countCookie.value = count.toString()
      basket.value.count = count
    }
    if (count || count === 0) {
      priceCookie.value = price.toString()
      basket.value.price = price
    }
  }

  const addToBasket = async (
    variants: { [id: number]: Variant },
    query?: Record<string, unknown>
  ) => {
    const items = Object.values(variants).map((variant) => ({
      product: variant.id,
      quantity: variant?.count ?? 1,
      services: variant.surcharges
        ? Object.keys(variant.surcharges).map((id) => ({
            product: new Number(id),
            quantity: 1,
            productProduct:
              variant.surcharge.find((sur) => sur.id == new Number(id))?.productProduct ?? null,
          }))
        : [],
    }))

    if (!basket.value.guid) {
      // Vytvoříme košík
      try {
        const { data } = await $customFetch<{ data: Basket }>('/public/baskets', query, undefined, {
          method: 'post',
          body: { items },
        })
        basket.value.guid = data.guid
        guidCookie.value = data.guid
        basket.value.count = data.countItems
        countCookie.value = data.countItems.toString()
        basket.value.price = data.totalPriceVat.amount
        priceCookie.value = data.totalPriceVat.amount.toString()
        return data
      } catch (e) {
        throw e
      }
    } else {
      // Vložit do už existujícího košíku
      try {
        const { data } = await $customFetch<{ data: Basket }>(
          `/public/baskets/${basket.value.guid}`,
          query,
          undefined,
          {
            method: 'patch',
            body: { items, increase: true },
          }
        )
        basket.value.count = data.countItems
        countCookie.value = data.countItems.toString()
        basket.value.price = data.totalPriceVat.amount
        priceCookie.value = data.totalPriceVat.amount.toString()
        return data
      } catch (e) {
        throw e
      }
    }
  }

  const changeBasket = async (
    items?: Item[],
    note?: string | null,
    query?: Record<string, unknown>
  ) => {
    const body: { items?: Item[]; note?: string | null; increase: boolean } = {
      items: undefined,
      increase: false,
      note: undefined,
    }
    if (items) {
      body.items = items
    } else {
      delete body.items
    }
    if (note !== undefined) {
      body.note = note
    } else {
      delete body.note
    }
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}`,
        query,
        undefined,
        {
          method: 'patch',
          body,
        }
      )
      basket.value.count = data.countItems
      countCookie.value = data.countItems.toString()
      basket.value.price = data.totalPriceVat.amount
      priceCookie.value = data.totalPriceVat.amount.toString()
      return data
    } catch (e) {
      throw e
    }
  }

  const changeIdentification = async (
    personal: string,
    tax: string,
    query?: Record<string, unknown>
  ) => {
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}`,
        query,
        undefined,
        {
          method: 'patch',
          body: {
            personalIdentification: personal,
            taxIdentification: tax,
          },
        }
      )
      return data
    } catch (e) {
      throw e
    }
  }

  const applyCoupon = async (code: string, query?: Record<string, unknown>) => {
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}/coupons/${code}`,
        query,
        undefined,
        {
          method: 'post',
          body: {},
        }
      )
      basket.value.price = data.totalPriceVat.amount
      priceCookie.value = data.totalPriceVat.amount.toString()
      return data
    } catch (e) {
      throw e
    }
  }

  const removeCoupon = async (code: string, query?: Record<string, unknown>) => {
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}/coupons/${code}`,
        query,
        undefined,
        {
          method: 'delete',
          body: {},
        }
      )
      basket.value.price = data.totalPriceVat.amount
      priceCookie.value = data.totalPriceVat.amount.toString()
      return data
    } catch (e) {
      throw e
    }
  }

  const changeAddress = async (address: Address, query?: Record<string, unknown>) => {
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}/addresses`,
        query,
        undefined,
        {
          method: 'put',
          body: {
            address,
          },
        }
      )
      return data
    } catch (e) {
      throw e
    }
  }

  const saveData = async (
    value: { [key: string]: string | number | boolean | null },
    query?: Record<string, unknown>
  ) => {
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}/data`,
        query,
        undefined,
        {
          method: 'put',
          body: {
            data: value,
          },
        }
      )
      return data
    } catch (e) {
      throw e
    }
  }

  const saveDelivery = async (delivery: Slot | null, query?: Record<string, unknown>) => {
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}/deliveries`,
        query,
        undefined,
        {
          method: 'put',
          body: {
            ...delivery,
          },
        }
      )
      return data
    } catch (e) {
      throw e
    }
  }

  const savePayment = async (payment: string, query?: Record<string, unknown>) => {
    try {
      const { data } = await $customFetch<{ data: Basket }>(
        `/public/baskets/${basket.value.guid}/payments`,
        query,
        undefined,
        {
          method: 'put',
          body: {
            payment,
          },
        }
      )
      return data
    } catch (e) {
      throw e
    }
  }

  const finish = async (
    agree: boolean,
    marketingConsent: boolean,
    buyer: { phone: string; email: string },
    query?: Record<string, unknown>
  ) => {
    try {
      const { data } = await $customFetch<{
        data: {
          onlinePayment: boolean
          redirect: boolean
          redirectUri: string | null
          article: { value: string }
          guid: string
        }
      }>(`/public/baskets/${basket.value.guid}/actions`, query, undefined, {
        method: 'post',
        body: {
          type: 'createOrder',
          agree,
          marketingConsent,
          buyer,
        },
      })
      return data
    } catch (e) {
      throw e
    }
  }

  const clear = () => {
    basket.value = {
      guid: null,
      count: 0,
      price: 0,
    }
    guidCookie.value = null
    countCookie.value = '0'
    priceCookie.value = '0'
    useState<Address>('basketDeliveryAddress').value = { type: 'delivery' }
    useState<Address>('basket-invoice-address').value = { type: 'invoice' }
    useState('basket-buy-as-company').value = false
    useState('basket-same-as-delivery').value = false
    useState('basket-tax-id').value = undefined
    useState('basket-personal-id').value = undefined
    useState('basket-selected-branch').value = undefined
  }

  return {
    basket,
    setBasket,
    addToBasket,
    loadBasket,
    changeBasket,
    changeIdentification,
    applyCoupon,
    removeCoupon,
    changeAddress,
    saveData,
    saveDelivery,
    savePayment,
    finish,
    clear,
  }
}
