import { type ComponentInternalInstance } from 'vue'

export const componentUUID = (customDataTestId?: string) => {
  const defaultInstance = getCurrentInstance()
  const { t } = useI18n()
  const currentCmponentUUID = ref(customDataTestId)
  const pageInputs = useState<{ [key: string]: true }>('pageInputs', () => ({}))

  const getPrettyName = (name: string) => {
    switch (name) {
      case 'AppTextInput':
        return 'text-input'
      // case 'AppButton':
      //   return 'button'
      default:
        return name.toLowerCase()
    }
  }

  const generateUUID = (
    instance: ComponentInternalInstance | null = defaultInstance,
    forceName?: string
  ) => {
    const name = instance?.type?.__name
      ? getPrettyName(instance?.type?.__name)
      : forceName
        ? getPrettyName(forceName)
        : undefined
    if (name) {
      if (!pageInputs.value[name]) {
        pageInputs.value[name] = true
        currentCmponentUUID.value = name
        return name
      } else {
        if (instance?.parent?.type.__name) {
          return generateUUID(instance?.parent, `${instance?.parent?.type.__name}-${name}`)
        } else {
          const lastChar = name.at(-1)
          const posibleNumberLastChar = lastChar !== undefined ? parseInt(lastChar) : undefined
          const isNumber =
            posibleNumberLastChar !== undefined ? !isNaN(posibleNumberLastChar) : false
          const generatedName = isNumber
            ? name.slice(0, -1) + (posibleNumberLastChar! + 1)
            : name + '-1'
          return generateUUID(null, generatedName)
        }
      }
    }
  }
  onBeforeMount(() => {
    if (customDataTestId) {
      if (pageInputs.value[customDataTestId]) {
        console.warn(
          `data-test-attribut ${customDataTestId} ${t('se nachází na více místech (není unikátní)')}`
        )
      } else {
        pageInputs.value[customDataTestId] = true
      }
    }
  })
  onBeforeUnmount(() => {
    if (currentCmponentUUID.value) {
      delete pageInputs.value[currentCmponentUUID.value]
    }
  })
  return { generateUUID }
}
