import type { Link, LinkTypes } from '@/types/link'

export const useEditor = () => {
  const replaceLinks = (
    type: LinkTypes,
    linkId: string,
    serializedData?: {
      [key: string]: {
        [key: string]: Link
      }
    },
    href = false
  ) => {
    const data = linkId ? serializedData?.[type]?.[linkId] : undefined
    const { slug, name, identifier: id, key } = data ?? {}
    if (!href) {
      return name
    }
    if (['email', 'phone', 'sms'].includes(type)) {
      return linkId
    }
    return redirect(type, { slug, id, key }, linkId)
  }
  const hydrateLinks = (html: string, data?: { [key: string]: Link[] }) => {
    const serializedData = (data?: { [key: string]: Link[] }) => {
      const parsedData: {
        [key: string]: {
          [key: string]: Link
        }
      } = {}
      Object.entries(data ?? {}).forEach(([type, typeData]) => {
        parsedData[type] = {}
        typeData.forEach((d) => {
          if (parsedData[type]) {
            parsedData[type][d.identifier] = d
          }
        })
      })
      return parsedData
    }
    let htmlWithData = html ?? ''
    const regexInHref = /href="\{\{([\w-]+)(?::([^|]+))?\|([^"]+)\}\}"/g
    const matchesInHref = [...(html ?? '').matchAll(regexInHref)]
    const replaceShortCodes = (match: RegExpExecArray) => {
      const [code, shortCodeType] = match
      htmlWithData = htmlWithData.replaceAll(code, `<ShortCode type='${shortCodeType}'/>`)
    }
    const replaceFunction = (match: RegExpExecArray, inHref: boolean) => {
      const [fullHash, linkType, id, type] = match
      if (linkType) {
        switch (type) {
          case 'link':
            const dataToReplace = replaceLinks(linkType, id, serializedData(data), inHref)
            const [phoneNumber, smsData] = dataToReplace?.split(':') ?? []
            if (dataToReplace) {
              const attribute =
                !dataToReplace.includes('http') && linkType === 'external'
                  ? `href="https://${dataToReplace}"`
                  : linkType === 'email'
                    ? `href="mailto:${dataToReplace}"`
                    : linkType === 'phone'
                      ? `href="tel:${phoneNumber}"`
                      : linkType === 'sms'
                        ? `href="sms:${phoneNumber}&body=${smsData}"`
                        : `href="${dataToReplace}"`
              htmlWithData = htmlWithData.replaceAll(fullHash, attribute)
            }
            break
        }
      }
    }
    matchesInHref.forEach((match) => {
      replaceFunction(match, true)
    })
    const regexOutsideHref = /(?<!href=")\{\{([\w-]+)(?::([^|]+))?\|([^"]+)\}\}/g
    const matchesOutsideHref = [...(html ?? '').matchAll(regexOutsideHref)]
    matchesOutsideHref.forEach((match) => {
      replaceFunction(match, false)
    })

    const shortCodeRegex = /{{shortCode:([^}]*)}}/g

    const shortCodes = [...(html ?? '').matchAll(shortCodeRegex)]
    shortCodes.forEach((match) => {
      replaceShortCodes(match)
    })
    return htmlWithData
  }
  const getShortCodeComponent = (type: string) => {
    switch (type) {
      case 'deliveryMap':
        return defineAsyncComponent(() => import('@/components/shortCodes/DeliveryMap.vue'))
    }
  }
  const hydrateComponents = (html: string, data?: { [key: string]: Link[] }) => {
    const parsedHTML = hydrateLinks(html, data)
    const div = document.createElement('div')
    div.innerHTML = parsedHTML
    const getAttrs = (
      node: Node & Element,
      deleteHrefAttribute?: boolean,
      hasDataLinkType?: boolean
    ) => {
      const attrs: { [key: string]: string } = {}
      for (const attr of node.attributes) attrs[attr.name] = attr.value
      if (node.tagName === 'A' && attrs.href) {
        if (hasDataLinkType) {
          attrs.to = attrs.href
        }
        if (deleteHrefAttribute) {
          delete attrs.href
        }
      }
      return attrs
    }
    const htmlToNodes = (nodes: NodeListOf<ChildNode>) => {
      return Array.from(nodes).map((element): VNode => {
        const elementWithType = element as Node & Element
        const dataLinkType = elementWithType.attributes?.getNamedItem('data-link-type')?.value
        const hasDataLinkType =
          !!dataLinkType && !['external', 'email', 'phone', 'sms'].includes(dataLinkType)
        const dataLinkTypeIsExternal =
          ['external', 'email', 'phone', 'sms'].includes(
            elementWithType.attributes?.getNamedItem('data-link-type')?.value ?? ''
          ) || !hasDataLinkType

        const LocaleLinkComponent = defineAsyncComponent(
          () => import('@/components/LocaleLink.vue')
        )
        LocaleLinkComponent.class = '!text-basic'

        const shortCodeType = elementWithType.attributes?.getNamedItem('type')?.value
        const isShortCode = elementWithType.tagName === 'SHORTCODE' && shortCodeType
        const component =
          isShortCode && shortCodeType
            ? getShortCodeComponent(shortCodeType)
            : elementWithType.tagName === 'A' && !dataLinkTypeIsExternal
              ? LocaleLinkComponent
              : elementWithType.tagName
        // 3 is #text, not sure how else to build this one
        if (elementWithType.nodeType === 3) return h('span', elementWithType.nodeValue ?? undefined)

        if (elementWithType.childNodes.length) {
          return h(
            component,
            getAttrs(elementWithType, !dataLinkTypeIsExternal, hasDataLinkType),
            elementWithType.tagName === 'A'
              ? { default: () => htmlToNodes(elementWithType.childNodes) }
              : htmlToNodes(elementWithType.childNodes)
          )
        }

        return h(
          component,
          getAttrs(elementWithType, !dataLinkTypeIsExternal, hasDataLinkType),
          elementWithType.innerHTML
        )
      })
    }
    const hElements = htmlToNodes(div.childNodes)

    return h('div', {}, hElements)
  }
  return { hydrateLinks, hydrateComponents }
}
