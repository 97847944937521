import type { Article, ArticlesDataList } from '~/types/article'
import type { StdObject } from '~/types/internal'

export const articleQuery = (
  articlaName?: string | Ref<string | undefined>,
  query?: object,
  thumbs?: StdObject,
  hasPreview?: { expires: string; signature: string },
  placeholderData?: { data: Article },
  forceData: boolean = false,
  enabled?: Ref<boolean>
) =>
  queryOptions({
    enabled,
    retry: 0,
    queryKey: ['articles', articlaName],
    queryFn: async (): Promise<{
      data: Article
    }> => {
      // Nefetchuje z BE pokud již data má ale queryClient není k dispozici (např. v middleweru)
      if (forceData && placeholderData) {
        return placeholderData
      }
      const res = await $customFetch<{ data: Article }>(
        `/public/articles/${typeof articlaName === 'string' ? articlaName : articlaName!.value}`,
        {
          ...query,
          ...(hasPreview ?? {}),
        },
        thumbs
      )
      return res as { data: Article }
    },
    placeholderData,
  })

export const articlesQuery = (
  query?: object,
  thumbs?: StdObject,
  hasPreview?: { expires: string; signature: string },
  placeholderData?: { data: Article[] },
  forceData: boolean = false
) =>
  queryOptions({
    queryKey: ['articles'],
    queryFn: async (): Promise<{
      data: Article[]
    }> => {
      // Nefetchuje z BE pokud již data má ale queryClient není k dispozici (např. v middleweru)
      if (forceData && placeholderData) {
        return placeholderData
      }
      const res = await $customFetch<{ data: Article[] }>(
        '/public/articles',
        {
          ...query,
          ...(hasPreview ?? {}),
        },
        thumbs
      )
      return res as { data: Article[] }
    },
    placeholderData,
  })

export const infiniteArticlesQuery = (
  query?: Ref<object>,
  thumbs?: StdObject,
  hasPreview?: { expires: string; signature: string },
  placeholderData?: ArticlesDataList,
  forceData: boolean = false
) =>
  infiniteQueryOptions({
    queryKey: ['articles', query],
    queryFn: async ({ pageParam }): Promise<ArticlesDataList> => {
      // Nefetchuje z BE pokud již data má ale queryClient není k dispozici (např. v middleweru)
      if (forceData && placeholderData) {
        return placeholderData
      }
      const res = await $customFetch<ArticlesDataList>(
        '/public/articles',
        {
          ...query?.value,
          page: {
            ...(query?.value && 'page' in query.value && typeof query.value.page === 'object'
              ? query.value.page
              : {}),
            number: pageParam,
          },
          ...(hasPreview ?? {}),
        },
        thumbs
      )
      return res
    },
    initialPageParam: 1,
    getNextPageParam: (currentPage) => {
      if (currentPage.meta.current_page === currentPage.meta.last_page) {
        return undefined
      }
      return currentPage.meta.current_page + 1
    },
    getPreviousPageParam: (currentPage) => {
      if (currentPage.meta.current_page === 1) {
        return undefined
      }
      return currentPage.meta.current_page - 1
    },
  })
