import type { Link, LinkOptional, LinkTypes } from '@/types/link'
import {
  ARTICLE_ABOUT_US,
  ARTICLE_CONTACTS,
  ARTICLE_HOME,
  ARTICLE_SHIPPING,
  ARTICLE_TERMS,
  ARTICLE_COMPLAINT,
  ARTICLE_EU,
} from '~/constants/articles'
export const redirect = (type: LinkTypes, data: Link, link: string) => {
  const { $localePath } = useNuxtApp()
  const router = useRouter()
  switch (type) {
    case 'anchor':
      return `#${link}`
    case 'article':
      return getPermanentRoute(
        data,
        router.resolve($localePath({ name: 'article-detail', params: { ...data } }))?.path
      )
    case 'article-list':
      return getPermanentRoute(data, router.resolve($localePath({ name: 'article-list' }))?.path)
    case 'external':
      return link
    case 'login':
      return getPermanentRoute(data, router.resolve($localePath({ name: 'login' }))?.path)
    case 'logout':
      return getPermanentRoute(data, router.resolve($localePath({ name: 'logout' }))?.path)
    case 'branch':
      return getPermanentRoute(
        data,
        router.resolve($localePath({ name: 'branch-detail', params: { ...data } }))?.path
      )
    case 'product':
      return getPermanentRoute(
        data,
        router.resolve($localePath({ name: 'product-detail', params: { ...data } }))?.path
      )
    case 'product-list':
      return getPermanentRoute(data, router.resolve($localePath({ name: 'product-list' }))?.path)
    case 'product-list-color':
      return getPermanentRoute(
        data,
        router.resolve($localePath({ name: 'category-detail', params: { ...data, c: 'c' } }))?.path
      )
    case 'product-list-flower':
      return getPermanentRoute(
        data,
        router.resolve($localePath({ name: 'category-detail', params: { ...data, c: 'f' } }))?.path
      )
    case 'product-list-occasions':
      return getPermanentRoute(
        data,
        router.resolve($localePath({ name: 'category-detail', params: { ...data, c: 'o' } }))?.path
      )
    case 'register':
      return getPermanentRoute(data, router.resolve($localePath({ name: 'register' }))?.path)
    case 'section':
      return
    case 'home':
      return '/'
  }
}

export const getPermanentRoute = (data: LinkOptional, route?: string) => {
  const router = useRouter()
  const { $localePath } = useNuxtApp()

  if (!data.key) {
    return route
  }
  switch (data.key?.value) {
    case ARTICLE_CONTACTS:
      return router.resolve($localePath({ name: 'contact-us' }))?.path
    case ARTICLE_ABOUT_US:
      return router.resolve($localePath({ name: 'about-us' }))?.path
    case ARTICLE_SHIPPING:
      return router.resolve($localePath({ name: 'prices-of-transport' }))?.path
    case ARTICLE_HOME:
      return router.resolve($localePath({ name: 'home' }))?.path
    case ARTICLE_TERMS:
      return router.resolve($localePath({ name: 'terms-and-conditions' }))?.path
    case ARTICLE_COMPLAINT:
      return router.resolve($localePath({ name: 'complaints-policy' }))?.path
    case ARTICLE_EU:
      return router.resolve($localePath({ name: 'digital-transformation' }))?.path
    default:
      return route
  }
}
