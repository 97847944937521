
// @ts-nocheck


export const localeCodes =  [
  "en",
  "cs",
  "ru"
]

export const localeLoaders = {
  "en": [],
  "cs": [],
  "ru": []
}

export const vueI18nConfigs = [
  () => import("../../../../app/i18n.config.ts?hash=1ddd7652&config=1" /* webpackChunkName: "__app_i18n_config_ts_1ddd7652" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": false,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./app/i18n.config.ts",
  "locales": [
    "en",
    "cs",
    "ru"
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "login": {
      "cs": "/prihlaseni",
      "en": "/login"
    },
    "logout": {
      "cs": "/odhlaseni",
      "en": "/logout"
    },
    "products/index": {
      "cs": "/seznam-produktu",
      "en": "/product-list"
    },
    "products/[slug]/p[id]": {
      "cs": "/[slug]/p-[id]",
      "en": "/[slug]/p-[id]"
    },
    "categories/[slug]/f[c][id]": {
      "cs": "/[slug]/f-[c]-[id]",
      "en": "/[slug]/f-[c]-[id]"
    },
    "articles/index": {
      "cs": "/seznam-clanku",
      "en": "/article-list"
    },
    "articles/[slug]/a[id]": {
      "cs": "/[slug]/a-[id]",
      "en": "/[slug]/a-[id]"
    },
    "sections/[slug]/s[id]": {
      "cs": "/[slug]/s-[id]",
      "en": "/[slug]/s-[id]"
    },
    "review/r[type]/index": {
      "cs": "/r[type]",
      "en": "/r[type]"
    },
    "profile/loyaltyProgram/index": {
      "cs": "/profil/vernostni-program",
      "en": "/profile/loyalty-program"
    },
    "loyaltyProgram/index": {
      "cs": "/vernostni-program",
      "en": "/loyalty-program"
    },
    "reset-password": {
      "cs": "/reset-hesla",
      "en": "/reset-password"
    },
    "register": {
      "cs": "/registrace",
      "en": "/register"
    },
    "contact-us/index": {
      "cs": "/kvetinarstvi-praha",
      "en": "/contact-us"
    },
    "prices-of-transport/index": {
      "cs": "/cenik-dopravy-a-podminky-doruceni",
      "en": "/prices-of-transport"
    },
    "about-us": {
      "cs": "/o-nas",
      "en": "/about-us"
    },
    "digital-transformation/index": {
      "cs": "/digitalni-transformace-podniku-kvetinovy-svet",
      "en": "/digital-transformation"
    },
    "terms-and-conditions/index": {
      "cs": "/obchodni-podminky-belles-fleurs",
      "en": "/terms-and-condition"
    },
    "complaints-policy/index": {
      "cs": "/reklamacni-rad-belles-fleurs",
      "en": "/complaints-policy"
    },
    "order/[guid]": {
      "cs": "/objednavka/[guid]",
      "en": "/order/[guid]"
    },
    "profile": {
      "cs": "/profil",
      "en": "/profile"
    },
    "profile/edit": {
      "cs": "/profil/uprava",
      "en": "/profile/edit"
    },
    "profile/addresses/index": {
      "cs": "/profil/adresy",
      "en": "/profile/addresses"
    },
    "profile/orders/history": {
      "cs": "/profil/objednavky/historie",
      "en": "/profile/orders/history"
    },
    "profile/orders/[guid]": {
      "cs": "/profil/objednavky/[guid]",
      "en": "/profile/orders/[guid]"
    },
    "basket/supplement": {
      "cs": "/kosik/doplnky",
      "en": "/basket/supplement"
    },
    "basket/summary": {
      "cs": "/kosik/prehled",
      "en": "/basket/summary"
    },
    "basket/delivery/type": {
      "cs": "/kosik/typ-doruceni",
      "en": "/basket/delivery-type"
    },
    "basket/delivery/courier-place": {
      "cs": "/kosik/doruceni-kam",
      "en": "/basket/courier-where"
    },
    "basket/delivery/courier-person": {
      "cs": "/kosik/doruceni-komu",
      "en": "/basket/courier-who"
    },
    "basket/delivery/pickup-place": {
      "cs": "/kosik/vyzvednuti-kde",
      "en": "/basket/pickup-where"
    },
    "basket/delivery/pickup-person": {
      "cs": "/kosik/vyzvednuti-kdo",
      "en": "/basket/pickup-who"
    },
    "basket/payment": {
      "cs": "/kosik/platba",
      "en": "/basket/payment"
    },
    "basket/finish": {
      "cs": "/kosik/dokonceni",
      "en": "/basket/finish"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "language": "en",
    "code": "en"
  },
  {
    "language": "cs",
    "code": "cs"
  },
  {
    "language": "ru",
    "code": "ru"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
